import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Badge,
    Button,
    Card,
    CardGroup,
    Col,
    Container,
    ListGroup,
    Row,
} from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import {
    ReportingDataContext,
    SessionContext,
} from '../libs/contexts.js';
import { pipelineStatus } from '../libs/ui';
import { sortBrokers } from '../libs/utils';

const BrokerView = ({ name }) => {

    const { reportingData } = useContext(ReportingDataContext);
    const [broker, setBroker] = useState({});

    useEffect(() => {

        if (name) {
            let newBroker = {};
            for (let item of reportingData.brokers) {
                if (name === item.name) {

                    setBroker(item);
                }
            }
        }
    }, [name, reportingData.brokers]);

    return (
        <Container>
            <Row>
                <Col>
                    {broker &&
                        <Card>
                            <Card.Header>
                                <b>{broker.name}</b> email subscriptions
                                <small className='float-end'><Badge className='text-white' bg='black'>{broker.stage}</Badge></small>
                            </Card.Header>
                            <Card.Body>

                                {broker.subscriptions ?
                                    <>
                                        <ListGroup>
                                            {broker.subscriptions.map((subscription, index) => (
                                                <ListGroup.Item key={index}>{subscription}</ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </>
                                    :
                                    <Badge>No email subscriptions</Badge>
                                }
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    )

}

export const PipelineScreen = () => {

    const { reportingData } = useContext(ReportingDataContext);
    const { session, setSession } = useContext(SessionContext);
    const [broker, setBroker] = useState(false);


    return (
        <Container>
            <Row>
                <Col>
                    <BrokerPipeline setBroker={setBroker} />
                </Col>
            </Row>
            <Row>
                <Col>
                    {broker &&
                        <BrokerView name={broker} />
                    }
                </Col>
            </Row>
        </Container>);

}

export const PipelineSummary = ({ }) => {

    const navigate = useNavigate();
    const { reportingData } = useContext(ReportingDataContext);
    const [stages, setStages] = useState({});
    const [order, setOrder] = useState(['considered', 'interested', 'committed', 'confirmed', 'inception', 'participating']);

    useEffect(() => {
        if (reportingData.brokers) {
            let newStages = {};
            for (let broker of reportingData.brokers) {
                if (undefined !== broker.stage) {
                    if (undefined === newStages[broker.stage]) {
                        newStages[broker.stage] = [];
                    }
                    switch (broker.stage) {
                        case 'considered':
                            broker.bg = 'danger';
                            broker.caption = 'Low interest';
                            break;
                        case 'interested':
                            broker.bg = 'secondary';
                            broker.caption = 'Interested';
                            break;
                        case 'committed':
                            broker.bg = 'info';
                            broker.caption = 'Committed';
                            break;
                        case 'confirmed':
                            broker.bg = 'warning';
                            broker.caption = 'Confirmed';
                            break;
                        case 'inception':
                            broker.bg = 'primary';
                            broker.caption = 'Pending';
                            break;
                        default:
                            broker.bg = 'success';
                            broker.caption = 'Participating';
                            break;
                    }
                    newStages[broker.stage].push(broker);
                }
            }
            setStages(newStages);
        }
        //onsole.log("Brokers", reportingData.brokers);
    }, [reportingData.brokers])

    return (
        <Card className='card-summary-alt'>
            <Card.Header>Onboarding pipeline</Card.Header>
            <Card.Body>
                <Container fluid>


                    <Row className='mt-4 mb-4'>
                        <Col className='text-center'>
                            {stages &&
                                <>
                                    {order.map((stage) => (
                                        <React.Fragment key={stage}>
                                            {stages[stage] && stages[stage].map((broker) => (
                                                <Badge title={broker.caption} key={broker.id} bg={broker.bg} className="badge-summary">
                                                    {broker.name}
                                                </Badge>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </>}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
            <Card.Footer className='text-end'>
                <Button onClick={() => navigate('/pipeline')} variant="outline-primary" className='btn-icon'><FaArrowRight /></Button>
            </Card.Footer>
        </Card>

    );
}

export const BrokerPipeline = ({ setBroker }) => {

    const { reportingData } = useContext(ReportingDataContext);
    const [brokers, setBrokers] = useState([]);
    const [selected, setSelected] = useState(false);

    const stages = useMemo(
        () => {
            let columns = pipelineStatus;
            return columns;
        },
        []
    );

    useEffect(() => {


        if (reportingData.brokers) {
            let newBrokers = sortBrokers({ brokers: reportingData.brokers });
            setBrokers(newBrokers);
        }


    }, [reportingData.brokers]);



    return (<>
        <Container className='mb-4'>
            <Row>
                <Col>
                    <CardGroup>
                        {stages.map((value, key) => (
                            <Card key={key}>
                                <Card.Header className={`bg-${value.bg} text-${value.text}`}>
                                    {value.name}
                                </Card.Header>
                                <Card.Body>
                                    {brokers.map((broker, index) => (
                                        <React.Fragment key={index}>
                                            {value.id === broker.stage &&
                                                <PipelineItem name={broker.name} setBroker={setBroker} />
                                            }
                                        </React.Fragment>
                                    ))}
                                </Card.Body>
                            </Card>
                        ))}
                    </CardGroup>
                </Col>
            </Row>
        </Container>
    </>)


}

const PipelineItem = ({ name, setBroker }) => {

    const { session, setSession } = useContext(SessionContext);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {

        if (name && name === session.inFocus) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }

    }, [name, session.inFocus]);

    const onChange = (e) => {
        setBroker(name);
        setSession({ inFocus: name });
    }

    return (
        <Card className={isActive ? "mb-2 bg-light text-center pipeline-write clicker" : "mb-2 text-center pipeline-write clicker"}>
            <Card.Body onClick={onChange}>{name}</Card.Body>
        </Card>
    )
}

