import {
    useContext,
    useEffect,
    useState,
    useRef,
} from 'react';

import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    ListGroup,
    Row,
    Tab,
    Tabs,
    Form,
    Dropdown,
    DropdownButton
} from 'react-bootstrap';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { BsEnvelopeAt } from 'react-icons/bs';
import { useParams } from 'react-router-dom';

// import { QuoterCard } from '../app/quoters';
// import { InfoCard } from '../controls/cards';
import { Hero } from '../controls/hero';
import { ReportingDataContext, SessionContext } from '../libs/contexts';
//import { getLead } from '../libs/demo';
import { asCurrency } from '../libs/i18n';
import { AlternativeCard } from './alternatives';
import { FaceValueCard } from './facevalue';
import {
    CostObjection,
    ProductObjection,
    TimingObjection,
    ValueObjection,
} from './objections';
import { SalesTips } from './sales-tips';
import { UpsellCard } from './upsell';
import * as api from '../libs/api.js';

export const Lead = () => {

    let { id } = useParams();
    const { reportingData } = useContext(ReportingDataContext);
    let [leadInfo, setLeadInfo] = useState(false);


    useEffect(() => {

        if (undefined !== reportingData && undefined !== reportingData.rows && id) {


            for (let row of reportingData.rows) {
                if (id === row.quote) {
                    setLeadInfo(row);
                    console.log("Lead data", row);
                }
            }

        }

    }, [id, reportingData]);

    return (<>


        {leadInfo ?
            <>
                <LeadEssentials lead={leadInfo} />
            </>
            :
            <></>}

        <Container fluid>
            <Row>
                <Col className="col-md-6 col-lg-5 col-xxl-3">
                    {leadInfo &&
                        <>
                            <SummaryCard lead={leadInfo} />
                            {leadInfo.facevalue &&
                                <FaceValueCard lead={leadInfo} />
                            }
                            {/* { leadInfo.age || leadInfo.current_age &&
                                <TriggerCard lead={ leadInfo } />
                            } */}
                        </>
                    }
                </Col>
                <Col className="px-0 mx-0">
                    <Container fluid>
                        {leadInfo.offers &&
                            <Row>
                                <Col className="col-md-6 col-xxl-5">
                                    <UpsellCard lead={leadInfo} />
                                </Col>
                                <Col className="col-md-6 col-xxl-5">
                                    <AlternativeCard lead={leadInfo} />
                                </Col>
                                <Col>
                                    <NeedsAdvisor />
                                </Col>
                            </Row>
                        }
                        {leadInfo.premium &&
                            <Row className="mt-2">
                                <Col className="col-md-12 col-xxl-10">
                                    <SalesObjections lead={leadInfo} />
                                </Col>
                                <Col>
                                    <SalesTips />
                                </Col>
                            </Row>
                        }
                        <Row className="mt-2">
                            <Col className="col-md-12 col-xxl-10">
                                <Notes lead={leadInfo} />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>);

}
const LeadEssentials = ({ lead }) => {
    const { session, setSession } = useContext(SessionContext);
    const [premium, setPremium] = useState("");
    const [currentStatus, setCurrentStatus] = useState(lead.status || '');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const leadsOptions = ['New Lead', 'Lead - Contacted', 'Lead - Needs Analysis', 'Lead - Application', 'Lead - Converted to Sale', 'Other (See Note)'];
    const salesOptions = ['Sale', 'Sale - Contacted', 'Sale - Needs Analysis', 'Sale - Additional Products', 'Other (See Note)'];

    const handleSelect = (status) => {
        setCurrentStatus(status);

        const statusUpdateRow = {
            "id": lead.id,
            "category": `${lead.broker}#quotes`,
            "status": status
        }

        api.saveData({
            record: statusUpdateRow, token: session.token, next: () => {
                const timestamp = new Date().toISOString();
                setSession((prevSession) => ({
                    ...prevSession,
                    lastUpdated: timestamp,
                }));
            }
        });
    };

    const buttons = [
        <>
            <DropdownButton title={currentStatus} onSelect={handleSelect}>
                {dropdownOptions.map((option, index) => (
                    <Dropdown.Item key={index} eventKey={option}>
                        {option}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </>,
    ];

    useEffect(() => {
        if ('yes' === lead.approved) {
            setDropdownOptions(salesOptions);
        } else {
            setDropdownOptions(leadsOptions);
        }
    }, []);

    useEffect(() => {

        console.log("Lead premium", lead.premium, lead);
        if (lead.premium) {
            let newPremium = lead.premium;
            if (isNaN(newPremium)) {
                newPremium = newPremium.replace("$", "");
                newPremium = parseFloat(newPremium);
            }
            newPremium = asCurrency({ amount: newPremium }) + " per month";
            setPremium(newPremium);
        }

    }, [lead]);

    const displayName = ({ lead }) => {
        let parts = [];

        if (lead) {
            if (lead.first_name) {
                parts.push(lead.first_name);
            }
            if (lead.last_name) {
                parts.push(lead.last_name);
            }
        }

        if (parts.length > 0) {
            return parts.join(" ");
        } else {
            return parts.join("(no name provided)");
        }

    }

    const displaySummary = ({ lead }) => {

        let parts = [];

        if (lead) {
            if (lead.facevalue) {
                parts.push(asCurrency({ amount: lead.facevalue, dropPennies: true }))
            }
            if (lead.term) {
                parts.push(lead.term + " years");
            } else {
                if (lead.term_selected) {
                    parts.push(lead.term_selected + " ");
                }
            }
            if (lead.premium) {
                if (lead.premium.toString().startsWith('$')) {
                    parts.push(lead.premium);
                } else {
                    let premiumValue = parseFloat(lead.premium.toString());
                    if (!isNaN(premiumValue)) {
                        parts.push(lead.premium);
                    }
                }

            }
            if (lead.rider) {

                if (['yes', 'Yes', '1', 1].includes(lead.rider)) {
                    parts.push("with rider");
                }
                if (['no', 'No', '0', 0].includes(lead.rider)) {
                    parts.push("no rider");
                }

            }
        }

        if (parts.length > 0) {
            return parts.join(" / ");
        } else {
            return "The prospective customer didn't provide a lot of information";
        }


    }


    return (
        <>
            <Hero title={displayName({ lead: lead })} text={displaySummary({ lead: lead })} buttons={buttons} />
        </>

    )

}

const NeedsAdvisor = () => {

    return (
        <Card>
            <Card.Header>Reminder</Card.Header>
            <Card.Body>
                Remember to confirm needs analysis if proposing a different coverage
            </Card.Body>
        </Card>
    )

}

const SalesObjections = ({ lead }) => {

    return (
        <Card className="mb-2">
            <Card.Header className="bg-danger text-white">Objections handling</Card.Header>
            <Card.Body>
                {lead &&
                    <Tabs
                        defaultActiveKey="price"
                        id="objections-tab"
                        className="mb-3"
                    >
                        <Tab eventKey="price" title="Price">
                            <CostObjection lead={lead} />
                        </Tab>
                        <Tab eventKey="value" title="Value">
                            <ValueObjection lead={lead} />
                        </Tab>
                        <Tab eventKey="timing" title="Timing">
                            <TimingObjection lead={lead} />
                        </Tab>
                        <Tab eventKey="product" title="Product">
                            <ProductObjection lead={lead} />
                        </Tab>
                    </Tabs>
                }
            </Card.Body>
        </Card>
    )

}

const SummaryCard = ({ lead }) => {

    return (
        <Card className="mb-2">
            <Card.Body>
                <ListGroup variant="flush" className="mb-2">

                    {lead.phone &&
                        <ListGroup.Item>
                            <h5>{lead.phone}
                                <Button variant="outline-primary" className="float-end"><BiSolidPhoneCall /></Button>
                            </h5>

                        </ListGroup.Item>
                    }
                    {lead.email &&
                        <ListGroup.Item>
                            <h5>{lead.email}

                                <Button variant="outline-primary" className="float-end"><BsEnvelopeAt /></Button>
                            </h5>


                        </ListGroup.Item>
                    }
                </ListGroup>
                <p>
                    {"F" === lead.gender &&
                        <Badge>Female</Badge>
                    }
                    {"M" === lead.gender &&
                        <Badge>Male</Badge>
                    }&nbsp;
                    {lead.current_age &&
                        <Badge>{lead.current_age} years old</Badge>
                    }

                    {/* <Badge>smoker</Badge> */}
                </p>
                <p className="text-muted">
                    {lead.address1 &&
                        <>
                            {lead.address1} <br />
                        </>
                    }
                    {lead.city || lead.province &&
                        <>
                            {lead.city} &nbsp; {lead.province} <br />
                        </>
                    }
                    <br />
                    {lead.postal_code}
                </p>
            </Card.Body>
        </Card>

    )

}

const Notes = ({ lead }) => {

    const { session, setSession } = useContext(SessionContext);
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(lead.notes || '');
    const inputRef = useRef(null);

    useEffect(() => {
        setText(lead.notes || '');
    }, [lead.notes]);

    const handleBlur = () => {
        setIsEditing(false);
        const notesUpdateRow = {
            "id": lead.id,
            "category": `${lead.broker}#quotes`,
            "notes": text
        }

        api.saveData({
            record: notesUpdateRow, token: session.token, next: () => {
                const timestamp = new Date().toISOString();
                setSession((prevSession) => ({
                    ...prevSession,
                    lastUpdated: timestamp,
                }));
            }
        });
    };

    const handleChange = (e) => {
        if (e.target.value.length <= 1000) {
            setText(e.target.value);
        }
    };

    return (
        <Card className="mb-2">
            <Card.Header className="bg-primary text-white">Notes (Click to edit)</Card.Header>
            <Card.Body>
                {isEditing ? (
                    <>
                        <Form.Label>
                        </Form.Label>
                        <Form.Control
                            ref={inputRef}
                            as="textarea"
                            value={text}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoFocus
                        />
                        <div>{text.length}/1000 characters</div>
                    </>

                ) : text ? (
                    <span onClick={() => setIsEditing(true)}>{text}</span>
                ) : (
                    <Button onClick={() => setIsEditing(true)}>
                        Click to add notes
                    </Button>
                )}
            </Card.Body>
        </Card>
    )

}
