import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { BasicCard } from '../controls/cards';
import { ReadOnlyGrid } from '../controls/grids';
import {
  LayoutContext,
  ReportingDataContext,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n';
import * as config from '../config';

export const Everything = () => {

  const { reportingData } = useContext(ReportingDataContext);
  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const headerMapping = [
    { label: 'Lead date', value: 'date' },
    { label: 'First name', value: 'first_name' },
    { label: 'Last name', value: 'last_name' },
    { label: 'Broker defined status', value: 'status' },
    { label: 'Broker notes', value: 'notes' },
    { label: 'Latest step', value: 'latest_step' },
    { label: 'Approved', value: 'approved' },
    { label: 'Face value', value: 'facevalue' },
    { label: 'Premium', value: 'premium' },
    { label: 'Term', value: 'term' },
    { label: 'Rider', value: 'rider' },
    { label: 'Gender', value: 'gender' },
    { label: 'Date of birth', value: 'dob' },
    { label: 'Age', value: 'age' },
    { label: 'Smoker', value: 'smoker' },
    { label: 'Address', value: 'address1' },
    { label: 'City', value: 'city' },
    { label: 'Province', value: 'province' },
    { label: 'Postal code', value: 'postal_code' },
    { label: 'Phone number', value: 'phone' },
    { label: 'Email address', value: 'email' },
    { label: 'Browser name', value: 'browser_name' },
    { label: 'Browser version', value: 'browser_version' },
    { label: 'Operating system', value: 'os' },
    { label: 'Lead date time', value: 'started' },
    { label: 'Campaign identifier tag', value: 'utm_campaign' },
    { label: 'Traffic source tag', value: 'utm_source' },
    { label: 'Marketing medium tag', value: 'utm_medium' }
  ];

  const onSelect = (lead) => {

    navigate('/leads/' + lead.quote);
  }

  useEffect(() => {

    const clonedData = {
      ...reportingData,
      rows: reportingData.rows.map(row => ({
        ...row,
        started: new Date(row.started).toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
          timeZone: 'UTC',
          timeZoneName: 'short'
        })
      }))
    };

    setRecords(clonedData);

  }, [reportingData]);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <span>All leads</span>
          <a href={`${config.gateway}/download/leads.csv`} className="btn btn-outline-primary">Download all leads</a>
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {records && records.rows && 0 < records.rows.length ? (
            <ReadOnlyGrid height={720} rows={records.rows} onSelect={onSelect} headerMapping={headerMapping} />)
            : (<Card body className="border-0">Sorry, you don't have any leads yet.</Card>)}
        </Card.Body>
        <Card.Footer>All leads (no time limit)</Card.Footer>
      </Card>

    </>
  )
}